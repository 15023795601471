import axios from "axios";
import { AuthProvider } from "../utils/types";
import { URLMapperResource } from "./dataProvider";
// @ts-ignore
import qs from "qs";
import {LOGIN_OPTIMIZED_TOKEN} from "../utils/CONST";

class WrapperAuthProvider {
    private authProvider: AuthProvider|undefined;
    private urlMappingResource: URLMapperResource;

    constructor(urlMappingResource: URLMapperResource) {
        this.urlMappingResource = urlMappingResource;
        this.configureAuthProvider();
    }

    configureAuthProvider = () => {
        this.authProvider = {
            login: (params: any): Promise<any> => {
                return axios({
                    baseURL: this.urlMappingResource.isResourceAndGetURL("login"),
                    url: `/${LOGIN_OPTIMIZED_TOKEN}`,
                    method: "POST",
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    data: {
                        _username: params.username,
                        _password: params.password,
                    },
                    transformRequest: (data) => qs.stringify(data),
                }).then((response) => response.data);
            },
        };
    };

    // @ts-ignore
    getProvider = (): AuthProvider => this.authProvider;
}

let instance: WrapperAuthProvider|null = null;

const getWrapperAuthProvider = (urlMappingResource: URLMapperResource) => {
    if (null === instance) instance = new WrapperAuthProvider(urlMappingResource);

    return instance;
};

export { getWrapperAuthProvider }