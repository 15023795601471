const FINGER_TOKEN_PREFIX = process.env.NEXT_PUBLIC_FINGER_TOKEN_PREFIX;
const MC_AUTO_AUTH_TOKEN_PREFIX = process.env.NEXT_PUBLIC_MC_AUTO_AUTH_TOKEN_PREFIX;
const FINGER_UNIQUE_VALUE = process.env.NEXT_PUBLIC_FINGER_UNIQUE_VALUE;

export const AFTER_LOGIN_REDIRECT_TO = 'after_login_redirect_to';
export const AFTER_ABOUT_REDIRECT_TO = 'after_about_redirect_to';

export const TYPE_NOTES_PATH = 'type_notes';
export const TYPE_NOTES_NAME = 'MEMOTAGPRO';
export const MC_NOTES_PATH = 'm_c_notes';
// export const UPLOAD_IMAGES = 'uploads/upload_file';
// export const UPLOAD_IMAGES = 'medias';
export const UPLOAD_MEDIAS = 'medias';
export const TYPE_NOTES = 'type_notes';
export const AVATAR_TRACEABILITY_NOTES_TYPE_MCNOTEHISTORY = 'MCNoteHistory';
export const AVATAR_TRACEABILITY_NOTES = 'avatar_traceability_notes';
export const METADATA_AVATAR_TYPES = 'metadata_avatar_types';
export const METADATA_AVATAR_TYPE_PROPERTIES = 'metadata_avatar_type_properties';
export const DEFAULT_START_CONTROL_PROCESSES = 'default_start_control_processes';
export const MCS_CROSS_STOCK = 'm_cs/cross-stock';
export const TEMPORARY_YOURLS = 'temporary_yourls';
export const AVATAR_TRACEABILITY = 'traceability/current_referencing';
export const AVATARS = 'avatars';
export const MCS = 'm_cs/cross-stock';
export const YOURLS = 'yourls';
export const EVENT_TYPES = 'ticketing/event_types_allowed_in_places';
export const PLACES_OR_EVENTS = 'ticketing/places_or_events';
export const PLACES_OR_EVENTS_PAIRING = 'ticketing/place_or_events/pairing';
export const EVENTS_COUNT = 'event/count';
export const AUTO_APP_INSTANCES = 'create_auto_auth_app_instances';
export const AVATAR_TEMPLATES = 'avatar_templates';
export const IMAGES = 'image';
export const FILES = 'files';
export const MEDIA = 'media';
export const TRACEABILITY_TRACKING = 'traceability/tracking';
export const SIMPLE_ZONES = 'simple_zones';
export const ZONE_CONFIGS = 'zone_configs';
export const USER_CONNECTED = 'user-connected';
export const GROUP_AVATARS = 'group_avatars';
export const ADD_GROUP = 'add_group';
export const REMOVE_GROUP = 'remove_group';
export const METADATA_LABELS = 'metadata_labels';
export const COMPANIES = 'companies';
export const TOKEN_WITH_ROLES = 'token-connected';
export const AVATAR_PROPERTIES = 'avatar_properties';
export const SEND_EMAILS = 'send_emails';
export const NOTE_HISTORY = 'm_c_note_histories';
export const TRACEABILITY_HISTORY = 'traceability_histories';
export const GROUP_METADATA_AVATAR_TYPES = 'group_metadata_avatar_types';
export const EVENT_USERS = 'ticketing/event_users';
export const USERS = 'users';

export const DATA_SOURCE_FINGER = 'MemoTAGPRO';
export const DATA_SOURCE_BACKOFFICE = 'backoffice';

export const InitialValuesRoute = '/initial-values';

export const UNIQUE_IDENTIFIER_BY_FINGER = FINGER_UNIQUE_VALUE;
export const METADATA_AVATAR_TYPE_EQUALPROCESSDEFNITION_MAPPER_MEMOTAG_DATA = 'MEMOTAGPRO';

export const MEDIA_FILE_TYPE = 'FILE';
export const MEDIA_IMAGE_TYPE = 'IMAGE';
export const MEDIA_LINK_TYPE = 'LINK';
export const MEDIA_AUDIO_TYPE = 'AUDIO';
export const RESOURCE_SUPPORTED_MEDIAS = 'avatar';
export const UPLOAD_IMAGES = 'medias';

export const NOTE_MEDIA_FILE = 'File';
export const NOTE_MEDIA_AUDIO = 'Audio';
export const NOTE_MEDIA_IMAGE = 'Image';

export const AUTHENTICATION_TYPE = 'Bearer';

export const INDEX_PAGE = '/';
export const BADGE_PAGE = '/badge';
export const LOGIN_PAGE = '/login';
export const LOGOUT_PAGE = '/logout';
export const ABOUT_PAGE = '/about';

export const AVATAR_CREATION_TYOURLS_STORAGE = '/avatar-creation-tyourls-storage';

//Modes

export const TOKEN_PARTS_PREFIX = `${FINGER_TOKEN_PREFIX}-tokenPart`;
export const TOKEN_PARTS_SIZE = `${FINGER_TOKEN_PREFIX}-tokenPartsSize`;

export const TOKEN_PARTS_MC_AUTO_AUTH_PREFIX = `${MC_AUTO_AUTH_TOKEN_PREFIX}-tokenPart`;
export const TOKEN_PARTS_MC_AUTO_AUTH_SIZE = `${MC_AUTO_AUTH_TOKEN_PREFIX}-tokenPartsSize`;
export const PAIRING_MODE = 'PAIRING_MODE';
export const SC360_PLANIFICATION_SERVICE = 'SC360_PLANIFICATION_SERVICE';
export const SC360_PAIRING_FROM_LINK = 'SC360_PAIRING_FROM_LINK';

export const INTEGRATION_ID = 'INTEGRATION_ID';
export const METADATAAVATARTYPEURI = 'METADATAAVATARTYPEURI';
export const PRODUCT_INFORMATIONS = 'PRODUCT_INFORMATIONS';
export const METADATALABEL_BUSINESSURI = 'METADATALABEL_BUSINESSURI';
export const METADATALABEL_BUSINESS = 'METADATALABEL_BUSINESS';
export const METADATALABEL_CONSTRUCTION_SITE = 'METADATALABEL_CONSTRUCTION_SITE';
export const METADATALABEL_CONSTRUCTION_SITEURI = 'METADATALABEL_CONSTRUCTION_SITEURI';
export const METADATALABEL_FACTORYURI = 'METADATALABEL_FACTORYURI';
export const METADATALABEL_FACTORY = 'METADATALABEL_FACTORY';
export const AVATAR_CLIENT_ID = 'AVATAR_CLIENT_ID';
export const AVATAR_CLIENT_NAME = 'AVATAR_CLIENT_NAME';
export const VALIDATION_TIME = 'VALIDATION_TIME';
export const LINKED_SOURCE_INFORMATION = 'LINKED_SOURCE_INFORMATION';
export const RESOURCE_CLIENT_ID = 'RESOURCE_CLIENT_ID';
export const CLASS_MAPPER = 'CLASS_MAPPER';
export const RAW_PROPERTIES = 'RAW_PROPERTIES';

export const CORE_INTEGRATION_CLASS_MAPPER_SUPPLEMENTS =
    'Platform360\\Core\\Domain\\Avatar\\AvatarClassMapperSupplements\\CoreIntegrationClassMapperSupplements';
export const EXTERNAL_SYSTEM_INTEGRATION_CLASS_MAPPER_SUPPLEMENTS =
    'Platform360\\Core\\Domain\\Avatar\\AvatarClassMapperSupplements\\ExternalSystemIntegration';

export const TOKEN_MAX_PARTS_SIZE = 2000;
// DEPRECATED
export const LOGIN_ANONYMOUS = 'optimized_token_semi_anonymous_easy_login_check';

export const LOGIN_OPTIMIZED_TOKEN = 'optimized_token_login_check';

export const TRACEABILITY_LINEAL = 'lineal';
export const TRACEABILITY_DECLARATIVE = 'declarative';
export const TRACEABILITY_DECLARATIVE_FORWARD = 'declarative_forward';
export const FINISH_PRODUCE_TOKEN = 'FINISH_PRODUCE_TOKEN';
export const ROLE_FILE_MANAGER_V1 = 'ROLE_FILE_MANAGER_V1';
export const ROLE_CORE_SC_PUBLISH_TOKEN = 'ROLE_CORE_SC_PUBLISH_TOKEN';

/*File Manager Cloud Roles**/
export const ROLE_FILE_MANAGER_CLOUD_READ = 'ROLE_FM_CLOUD_READ';
export const ROLE_FILE_MANAGER_CLOUD_READ_DOWNLOAD = 'ROLE_FM_CLOUD_READ_DOWNLOAD';
export const ROLE_FILE_MANAGER_CLOUD_WRITE = 'ROLE_FM_CLOUD_WRITE';

export const ROLE_CREATE_NFC_BADGE = 'ROLE_CREATE_NFC_BADGE';
export const ROLE_UPDATE_AVATAR_PROPERTY = 'ROLE_UPDATE_AVATAR_PROPERTY';
export const ROLE_CREATE_AVATAR_PROPERTY = 'ROLE_CREATE_AVATAR_PROPERTY';
export const ROLE_READ_AVATAR_PROPERTY = 'ROLE_READ_AVATAR_PROPERTY';

export const PROPERTY = 'Propriété';
export const VALUE = 'Valeur';

export const AUTO_ACTIONS = 'autoactions';
export const AUTO_ACTIONS_VERSION_1 = 'change-next-node:v1';
export const AUTO_ACTIONS_RESTART_VERSION_1 = 'change-next-node-restart:v1';
export const AUTO_ACTIONS_CHANGE_TIME = 'AUTO_ACTIONS_CHANGE_TIME';

export const NOTE_TYPE = 'note';
export const TRACEABILITY_TYPE = 'traceability';

export const MAINTENANCE_TAG = 'maintenance';
export const EMPTYING_TAG = 'emptying';
export const SPANC_TAG = 'spanc';
export const AIR_TABLE_TOKEN_IDENTIFIER = 'airtable-token';

export const INITIAL_MESSAGE_KEY = 'INITIAL_MESSAGE';

export const NODE_TYPE_TICKETING_JUST_BEFORE_START_REPAIRING = 'NODE_TYPE_TICKETING_JUST_BEFORE_START_REPAIRING';
export const NODE_TYPE_TICKETING_START = 'NODE_TYPE_TICKETING_START';
export const NODE_TYPE_TICKETING_REPAIRING = 'NODE_TYPE_TICKETING_REPAIRING';
export const NODE_TYPE_TICKETING_REPAIRED = 'NODE_TYPE_TICKETING_REPAIRED';
export const NODE_TYPE_END = 'NODE_TYPE_END';
export const NODE_TYPE_PAUSE_COUNTER_TIME = 'NODE_TYPE_PAUSE_COUNTER_TIME';
export const NODE_TYPE_TICKETING_WAITING_TAKE_INTO_ACCOUNT = 'NODE_TYPE_TICKETING_WAITING_TAKE_INTO_ACCOUNT';
export const NODE_TYPE_TICKETING_WAITING_TOOK_INTO_ACCOUNT = 'NODE_TYPE_TICKETING_WAITING_TOOK_INTO_ACCOUNT';
export const NODE_TYPE_TICKETING_OPENED = 'NODE_TYPE_TICKETING_OPENED';
export const NODE_TYPE_TICKETING_TOOK_INTO_ACCOUNT = 'NODE_TYPE_TICKETING_TOOK_INTO_ACCOUNT';
export const NODE_TYPE_TICKETING_TAKE_INTO_ACCOUNT = 'NODE_TYPE_TICKETING_TAKE_INTO_ACCOUNT';
export const NODE_TYPE_WAITING_ASSIGN_SERVICE_PROVIDER = 'NODE_TYPE_WAITING_ASSIGN_SERVICE_PROVIDER';

export const AVATAR_FILTERS = [
    NODE_TYPE_TICKETING_OPENED,
    NODE_TYPE_TICKETING_REPAIRING,
    NODE_TYPE_PAUSE_COUNTER_TIME,
    NODE_TYPE_TICKETING_TOOK_INTO_ACCOUNT,
    NODE_TYPE_TICKETING_JUST_BEFORE_START_REPAIRING,
    NODE_TYPE_TICKETING_TAKE_INTO_ACCOUNT,
    NODE_TYPE_TICKETING_WAITING_TAKE_INTO_ACCOUNT,
    NODE_TYPE_WAITING_ASSIGN_SERVICE_PROVIDER,
];

export const TAG_TICKETING_LOGO = 'ticketing_logo';
export const INCIDENT_KEY = 'ticketing.type.event';
export const LEVEL_KEY = 'ticketing.type.level';

export const CHECK_QRCODE_MODE = 'check-qrcode-01.*.*';
export const TAKE_INTO_ACCOUNT_MODE = 'take-into-account.*.*';
export const PRINT_MODE = 'print.*.*';
export const LOCAL_STORAGE_DEFAULT_CAMERA_DEVICE = 'default_camera_device';
export const ENVIRONMENT_CAMERA_DEVICE = 'environment';
export const USER_CAMERA_DEVICE = 'user';

export const ROLE_DELETE_AVATAR = 'ROLE_DELETE_AVATAR';
export const ROLE_TICKETING_VIEW_EDIT_STRUCTURE = 'ROLE_TICKETING_VIEW_EDIT_STRUCTURE';
export const ROLE_TICKETING_CREATE_PLACE_OR_EVENT = 'ROLE_TICKETING_CREATE_PLACE_OR_EVENT';
export const ROLE_UPDATE_AVATAR_ADD_MC = 'ROLE_UPDATE_AVATAR_ADD_MC';
export const ROLE_READ_COMPANY = 'ROLE_READ_COMPANY';
export const ROLE_UPDATE_MC = 'ROLE_UPDATE_MC';

export const EVOLIS_FACTURATION_ID = 'rikutec-001';

export const EVENT_TYPE_CUSTOM_PROCESS_FORCE_COMMENT_PROPERTY = 'EVENT_TYPE_CUSTOM_PROCESS_FORCE_COMMENT';
export const EVENT_TYPE_CUSTOM_PROCESS_FORCE_IMAGE_PROPERTY = 'EVENT_TYPE_CUSTOM_PROCESS_FORCE_IMAGE';
