import { createWrapper } from 'next-redux-wrapper';
import { applyMiddleware, createStore, Store } from 'redux';
import createSagaMiddleware, { Task } from 'redux-saga';
import { getWrapperAuthProvider } from '../services/providers/authProvider';
import { getWrapperProvider, URLMapperResource } from '../services/providers/dataProvider';
import getURLMapperResource from '../services/providers/utils/registerResource';
import getRequestIdGenerator, { RequestIdGeneratorInterface } from '../services/requestIdGenerator';
import { AuthProvider, DataProvider } from '../services/utils/types';
import rootSaga from './middlewares';
import rootReducer from './reducers';
import { getToken } from './selectors/user';

const bindMiddleware = (middlewares: any[]) => {
    if (process.env.NODE_ENV !== 'production') {
        const { composeWithDevTools } = require('redux-devtools-extension');
        return composeWithDevTools(applyMiddleware(...middlewares));
    }

    return applyMiddleware(...middlewares);
};

const appGetWrapperProvider = (urlMappingResource: URLMapperResource) => {
    const dataProviderConfig = {
        getHeaders() {
            return {};
        },
        urlMappingResource: urlMappingResource,
    };

    return getWrapperProvider(dataProviderConfig);
};

const urlMappingResource = getURLMapperResource();
const wrapperProvider = appGetWrapperProvider(urlMappingResource);
const wrapperAuthProvider = getWrapperAuthProvider(urlMappingResource);

export interface Services {
    dataProvider: DataProvider;
    authProvider: AuthProvider;
    requestIdGenerator: RequestIdGeneratorInterface;
}
export const services: Services = {
    dataProvider: wrapperProvider.getProvider(),
    authProvider: wrapperAuthProvider.getProvider(),
    requestIdGenerator: getRequestIdGenerator(),
};

export interface SagaStore extends Store {
    sagaTask?: Task;
}

export const makeStore = () => {
    const sagaMiddleware = createSagaMiddleware();

    let store: any = null;
    if (typeof window === 'undefined') {
        // @ts-ignore
        store = createStore(rootReducer, bindMiddleware([sagaMiddleware]));
    } else {
        let storeCreated = false;
        // @ts-ignore
        if (window.__NEXT_APP__STORE__) {
            try {
                // @ts-ignore
                store = createStore(
                    rootReducer,
                    // @ts-ignore
                    window.__NEXT_APP__STORE__.getState(),
                    bindMiddleware([sagaMiddleware])
                );
                storeCreated = true;
            } catch (e) {
                console.log(e);
            }
        }

        if (!storeCreated) {
            // @ts-ignore
            store = createStore(rootReducer, bindMiddleware([sagaMiddleware]));
        }

        // @ts-ignore
        window.__NEXT_APP__STORE__ = store;
    }

    (store as SagaStore).sagaTask = sagaMiddleware.run(rootSaga(services));

    wrapperProvider.updateFnGeTokenAndUpdateHeaders(
        // @ts-ignore
        () => getToken(store.getState())
    );

    return store;
};

export const wrapper = createWrapper(makeStore, { debug: false });
