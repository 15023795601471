export interface RequestIdGeneratorInterface {
    gen: () => string;
}

class RequestIdGenerator implements RequestIdGeneratorInterface {
    public gen = (): string => {
        return '_' + Math.random().toString(36).substr(2, 9);
    };
}

let instance: RequestIdGeneratorInterface | null = null;

const getRequestIdGenerator = (): RequestIdGeneratorInterface => {
    if (null === instance) instance = new RequestIdGenerator();

    return instance;
};

export default getRequestIdGenerator;
