import { ActionStructure, Company, MC } from '../../services/utils/types';

export const resolvedMCActionCreator: ActionStructure = {
    action: '360SC/RESOLVED_MC',
    fn: (mc: MC) => ({
        type: resolvedMCActionCreator.action,
        payload: {
            mc,
        },
    }),
};

export const resolvedCompanyImageUrlCreator: ActionStructure = {
    action: '360SC/RESOLVED_COMPANY_IMAGE_URL',
    fn: (url: string) => ({
        type: resolvedCompanyImageUrlCreator.action,
        payload: {
            url,
        },
    }),
};

export const resolvedCompanyActionCreator: ActionStructure = {
    action: '360SC/RESOLVED_COMPANY',
    fn: (company: Company) => ({
        type: resolvedCompanyActionCreator.action,
        payload: {
            company,
        },
    }),
};

export const resolvedMediaActionCreator: ActionStructure = {
    action: '360SC/RESOLVED_MEDIA',
    fn: (id: string, data: { url: string }) => ({
        type: resolvedMediaActionCreator.action,
        payload: {
            id,
            data,
        },
    }),
};

export const resolvedUserConnectedActionCreator: ActionStructure = {
    action: '360SC/RESOLVED_USER_CONNECTED',
    fn: (user: any) => ({
        type: resolvedUserConnectedActionCreator.action,
        payload: {
            user,
        },
    }),
};
