import { ThemeProvider } from '@mui/material/styles';
import { NextIntlProvider } from 'next-intl';
import type { AppProps } from 'next/app';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import HeadConf from '../components/common/head-conf/head-conf';
import { wrapper } from '../redux/store';
import '../styles/globals.css';
import mainTheme from '../themes/main-theme';

const MyApp = ({ Component, pageProps }: any) => {
    return (
        <ThemeProvider theme={mainTheme}>
            {/*@ts-ignore*/}
            <SnackbarProvider
                maxSnack={3}
                anchorOrigin={{
                    horizontal: 'center',
                    vertical: 'bottom',
                }}
            >
                <NextIntlProvider messages={pageProps.messages} locale={'en'}>
                    <HeadConf title={'360SmartConnect'} />
                    {/*@ts-ignore*/}
                    <Component {...pageProps} />
                </NextIntlProvider>
            </SnackbarProvider>
        </ThemeProvider>
    );
};

export default wrapper.withRedux(MyApp);
