import { destroyCookie, parseCookies as parseCookiesNookies, setCookie as setCookieNookies } from 'nookies';
import { CookieSetOptions } from 'universal-cookie';
import { TOKEN_PARTS_MC_AUTO_AUTH_PREFIX, TOKEN_PARTS_MC_AUTO_AUTH_SIZE } from './CONST';

const domain = process.env.NEXT_PUBLIC_DOMAIN;

import { splitToken } from './token';

export const DefaultCookieSetOptions: CookieSetOptions = {
    domain,
    path: '/',
    sameSite: 'none',
    secure: true,
};

export const parseCookies = (ctx: any) => parseCookiesNookies(ctx);

export const setCookie = (ctx: any, key: string, value: any, options: CookieSetOptions = {}) =>
    setCookieNookies(ctx, key, value, {
        ...DefaultCookieSetOptions,
        ...options,
    });

export const setCookieToken = (
    ctx: any,
    value: any,
    options: CookieSetOptions = {},
    cookies?: { [key: string]: any }
) => {
    const arrayToken: any[] = [];
    splitToken(value, arrayToken);

    arrayToken.map((tokenPart, index) =>
        setCookieNookies(ctx, `${TOKEN_PARTS_MC_AUTO_AUTH_PREFIX}${index}`, tokenPart, {
            ...DefaultCookieSetOptions,
            ...options,
        })
    );
    setCookieNookies(ctx, TOKEN_PARTS_MC_AUTO_AUTH_SIZE, String(arrayToken.length), {
        ...DefaultCookieSetOptions,
        ...options,
    });
};

export const setDestroyCookies = (ctx?: any) => {
    destroyCookie(ctx, `${TOKEN_PARTS_MC_AUTO_AUTH_PREFIX}0`, {
        ...DefaultCookieSetOptions,
    });
    destroyCookie(ctx, `${TOKEN_PARTS_MC_AUTO_AUTH_PREFIX}1`, {
        ...DefaultCookieSetOptions,
    });
    destroyCookie(ctx, `${TOKEN_PARTS_MC_AUTO_AUTH_PREFIX}2`, {
        ...DefaultCookieSetOptions,
    });
    destroyCookie(ctx, `${TOKEN_PARTS_MC_AUTO_AUTH_PREFIX}3`, {
        ...DefaultCookieSetOptions,
    });
    destroyCookie(ctx, `${TOKEN_PARTS_MC_AUTO_AUTH_PREFIX}4`, {
        ...DefaultCookieSetOptions,
    });
    destroyCookie(ctx, `${TOKEN_PARTS_MC_AUTO_AUTH_PREFIX}5`, {
        ...DefaultCookieSetOptions,
    });
    destroyCookie(ctx, TOKEN_PARTS_MC_AUTO_AUTH_SIZE, {
        ...DefaultCookieSetOptions,
    });
};
