import { URLMapperResource } from '../dataProvider';

let instance: URLMapperResource|null = null;

const CORE_API = process.env.NEXT_PUBLIC_CORE_API;
const CORE_API_URI = process.env.NEXT_PUBLIC_CORE_API_URI;

const getURLMapperResource = (): URLMapperResource => {
    if (instance === null) {
        instance = new URLMapperResource(`${CORE_API}`, `${CORE_API_URI}`);
    }

    return instance;
};

export default getURLMapperResource;
