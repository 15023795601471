import { setCookieToken, setDestroyCookies } from '../../services/utils/cookie';
import { decodeToken } from '../../services/utils/token';
import { removeTokenAction, resolvedTokenAction } from '../actions/securityActionCreator';

export type TokenData = {
    header: { typ: string; alg: string };
    payload: {
        iat: number;
        exp: number;
        username: string;
        user_id: number;
        email: string;
        company_name: string;
        company_id: string;
        company_id_facturation: string;
        company_id_categories: number[];
        roles: string[];
        is_anonymous: boolean;
    };
    signature: string;
};

export interface UserState {
    token: string | null;
    refreshToken: string | null;
    tokenData: TokenData | null;
}

export const InitialState: UserState = {
    token: null,
    refreshToken: null,
    tokenData: null,
};

const reducer = (state: UserState = InitialState, action: any) => {
    switch (action.type) {
        case resolvedTokenAction.action:
            const { token } = action.payload;
            const refreshToken = 'refreshToken' in action.payload ? action.payload.refreshToken : null;
            setCookieToken(null, token);
            return {
                ...state,
                token,
                refreshToken,
                tokenData: decodeToken(token),
            };
        case removeTokenAction.action: {
            setDestroyCookies();
            return {
                ...state,
                token: null,
                refreshToken: null,
                tokenData: null,
            };
        }
        default:
            return state;
    }
};

export default reducer;
