import { ActionStructureAction, Company, MC } from '../../services/utils/types';
import {
    resolvedCompanyActionCreator,
    resolvedCompanyImageUrlCreator,
    resolvedMCActionCreator,
    resolvedMediaActionCreator, resolvedUserConnectedActionCreator,
} from '../actions/resourcesActionCreator';

export interface ResourceState {
    mc: MC | null;
    company: Company | null;
    companyImageUrl: string | null;
    medias: { [key: string]: { url: string } };
    userConnected: {
        firstName: string;
        lastName: string;
        [key: string]: any;
    } | null;
}

export const InitialState: ResourceState = {
    mc: null,
    company: null,
    companyImageUrl: null,
    medias: {},
    userConnected: null,
};

const reducer = (state: ResourceState = InitialState, action: ActionStructureAction) => {
    switch (action.type) {
        case resolvedMCActionCreator.action: {
            const { mc } = action.payload;
            return {
                ...state,
                mc,
            };
        }
        case resolvedCompanyImageUrlCreator.action: {
            const { url } = action.payload;
            return {
                ...state,
                companyImageUrl: url,
            };
        }
        case resolvedCompanyActionCreator.action: {
            const { company } = action.payload;
            return {
                ...state,
                company,
            };
        }
        case resolvedMediaActionCreator.action: {
            const { id, data } = action.payload;
            return {
                ...state,
                medias: {
                    ...state.medias,
                    [id]: data,
                },
            };
        }
        case resolvedUserConnectedActionCreator.action: {
            const { user } = action.payload;
            return {
                ...state,
                userConnected: user,
            };
        }
        default:
            return state ? state : InitialState;
    }
};

export default reducer;
