import { AuthProvider, DataProvider, GeneralRequestAction, ReqStatus } from '../../services/utils/types';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { reqIdAction, reqStatusAction } from '../actions/fetchActionCreator';
import fetcher from './fetch/fetcher';

export const handleReqId = (dataProvider: DataProvider, authProvider: AuthProvider) =>
    function* (reqAction: GeneralRequestAction) {
        const {
            payload: {
                reqId,
                action: { type, payload, meta },
            },
        } = reqAction;

        yield put(reqStatusAction.fn(reqId, ReqStatus.PENDING));

        let response: any = null;
        try {
            // @ts-ignore
            response = yield call(fetcher, dataProvider, authProvider, type, meta?.resource, payload);

            let onSuccessCallback: any = () => {};
            if (meta && meta.callbacks?.onSuccess) onSuccessCallback = meta.callbacks.onSuccess;


            yield put({
                type: `${type}_SUCCESS`,
                payload: {
                    ...response,
                },
                meta: {
                    ...meta,
                },
            });

            const successAction = onSuccessCallback({ ...response }, { type, payload, meta });

            if (successAction && 'type' in successAction) {
                yield put(successAction);
            } else if (Array.isArray(successAction)) {
                yield all(successAction.filter((action) => action && 'type' in action).map((action) => put(action)));
            }

            yield put(reqStatusAction.fn(reqId, ReqStatus.DONE));

        } catch (e) {

            let onFailureCallback: any = () => {};
            if (meta && meta.callbacks?.onFailure) onFailureCallback = meta.callbacks.onFailure;

            const failureAction = onFailureCallback({ err: e }, { type, payload, meta });
            if (failureAction && 'type' in failureAction) {
                yield put(failureAction);
            }

            yield put(reqStatusAction.fn(reqId, ReqStatus.FAILURE, e));
        }

        let onTerminatingCallback: any = () => {};
        if (meta && meta.callbacks?.onTerminating) onTerminatingCallback = meta.callbacks.onTerminating;

        yield onTerminatingCallback({ ...response }, { type, payload, meta });
    };

export default (dataProvider: DataProvider, authProvider: AuthProvider) =>
    function* runRequest() {
        yield takeEvery(reqIdAction.action, handleReqId(dataProvider, authProvider));
    };
