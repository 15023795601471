import { combineReducers } from 'redux';
import resourcesReducer, { ResourceState } from './resourcesReducer';
import userReducer, { UserState } from './userReducer';

export interface GlobalState {
    user: UserState;
    resources: ResourceState;
}

const reducer = combineReducers({
    user: userReducer,
    resources: resourcesReducer,
});

export default reducer;
