import {
    ActionStructure,
    ActionStructureFetcher,
    Callbacks,
    CrudCreateAction,
    CrudUpdateAction,
    CrudGetListAction,
    FetcherRequestAction,
    GeneralRequestAction,
    Identifier,
    Pagination,
    ReqIdCrudCreateActionStructureFetcher,
    ReqIdCrudGetListActionStructureFetcher,
    ReqStatus,
    Sort,
    ReqIdCrudUpdateActionStructureFetcher, ReqIdCrudDeleteActionStructureFetcher, CrudDeleteAction,
} from '../../services/utils/types';

export const reqIdAction: ActionStructure = {
    action: '360SC/REQUEST_ACTION',
    fn: (action: FetcherRequestAction, reqId: string): GeneralRequestAction => ({
        type: reqIdAction.action,
        payload: {
            reqId,
            action,
        },
    }),
};

export const reqStatusAction: ActionStructure = {
    action: '360SC/REQUEST_STATUS_ACTION',
    fn: (reqId: string, status: ReqStatus, err?: any) => ({
        type: reqStatusAction.action,
        payload: {
            reqId,
            status,
            err,
        },
    }),
};

export const crudGetListAction: ActionStructure = {
    action: '360SC/GET_LIST',
    fn: (
        resource: string,
        pagination: Pagination,
        sort: Sort,
        filter: object,
        callbacks: Callbacks | undefined = undefined
    ): CrudGetListAction => ({
        type: crudGetListAction.action,
        payload: {
            filter,
            sort,
            pagination
        },
        meta: {
            resource,
            callbacks,
        },
    }),
};

export const crudGetListReqIdAction: ReqIdCrudGetListActionStructureFetcher = {
    action: reqIdAction.action,
    fn: (
        reqId: string,
        resource: string,
        pagination: Pagination,
        sort: Sort,
        filter: object,
        callbacks: Callbacks | undefined = undefined
    ): GeneralRequestAction => ({
        type: crudGetListReqIdAction.action,
        payload: {
            reqId,
            // @ts-ignore
            action: crudGetListAction.fn(resource, pagination, sort, filter, callbacks),
        },
    }),
};

export const crudGetOneAction: ActionStructure = {
    action: '360SC/GET_ONE',
    fn: (resource: string, id: Identifier, callbacks: Callbacks | undefined = undefined) => ({
        type: crudGetOneAction.action,
        payload: {
            id,
        },
        meta: {
            resource,
            callbacks,
        },
    }),
};

export const crudGetOneReqIdAction: ActionStructureFetcher = {
    action: reqIdAction.action,
    fn: (reqId: string, resource: string, id: Identifier, callbacks: Callbacks | undefined = undefined) => ({
        type: crudGetOneReqIdAction.action,
        payload: {
            reqId,
            action: crudGetOneAction.fn(resource, id, callbacks),
        },
    }),
};

export const crudCreateAction: ActionStructureFetcher = {
    action: '360SC/CREATE',
    fn: (resource: string, data: any, callbacks: Callbacks | undefined = undefined): CrudCreateAction => ({
        type: crudCreateAction.action,
        payload: {
            data,
        },
        meta: {
            resource,
            callbacks,
        },
    }),
};

export const crudCreateReqIdAction: ReqIdCrudCreateActionStructureFetcher = {
    action: reqIdAction.action,
    fn: (reqId: string, data: any, resource: string, callbacks: Callbacks | undefined) => ({
        type: crudCreateReqIdAction.action,
        payload: {
            reqId,
            action: crudCreateAction.fn(resource, data, callbacks),
        },
    }),
};

export const crudUpdateAction: ActionStructureFetcher = {
    action: "360SC/UPDATE",
    fn: (id: Identifier, resource: string, data: any, previousData: any = null, callbacks: Callbacks | undefined = undefined): CrudUpdateAction => ({
        type: crudUpdateAction.action,
        payload: {
            id, data, previousData
        },
        meta: {
            resource,
            callbacks
        }
    })
}

export const crudUpdateReqIdAction: ReqIdCrudUpdateActionStructureFetcher = {
    action: reqIdAction.action,
    fn: (
        reqId: string, id: Identifier, resource: string, data: any, previousData: any = null, callbacks: Callbacks | undefined = undefined
    ) => ({
        type: reqIdAction.action,
        payload: {
            reqId,
            action: crudUpdateAction.fn(id, resource, data, previousData, callbacks)
        }
    })
}

export const crudDeleteAction: ActionStructureFetcher = {
    action: '360SC/DELETE',
    fn: (id: Identifier, resource: string, callbacks: Callbacks | undefined = undefined): CrudDeleteAction => ({
        type: crudDeleteAction.action,
        payload: {
            id,
        },
        meta: {
            resource,
            callbacks,
        },
    }),
};

export const crudDeleteReqIdAction: ReqIdCrudDeleteActionStructureFetcher = {
    action: reqIdAction.action,
    fn: (reqId: string, id: Identifier, resource: string, callbacks: Callbacks | undefined = undefined) => ({
        type: reqIdAction.action,
        payload: {
            reqId,
            action: crudDeleteAction.fn(id, resource, callbacks),
        },
    }),
};
