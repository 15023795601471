import { AuthProvider, DataProvider } from '../../../services/utils/types';
import {
    crudCreateAction,
    crudDeleteAction,
    crudGetListAction,
    crudGetOneAction,
    crudUpdateAction
} from '../../actions/fetchActionCreator';
import { loginAction } from '../../actions/securityActionCreator';

export default (
    dataProvider: DataProvider,
    authProvider: AuthProvider,
    type: string,
    resource: string,
    payload: any
) => {
    switch (type) {
        case crudGetListAction.action:
            return dataProvider.getList(resource, payload);
        case crudGetOneAction.action:
            return dataProvider.getOne(resource, payload);
        case loginAction.action:
            return authProvider.login(payload);
        case crudCreateAction.action:
            return dataProvider.create(resource, payload);
        case crudUpdateAction.action:
            return dataProvider.update(resource, payload)
        case crudDeleteAction.action:
            return dataProvider.delete(resource, payload)
        // case STANDARD_ACIONS_TYPE.CREATE:
        //     return dataProvider.create(resource, payload);
        // case STANDARD_ACIONS_TYPE.UPDATE:
        //     return dataProvider.update(resource, payload);
        // case STANDARD_ACIONS_TYPE.DELETE:
        //     return dataProvider.delete(resource, payload);
        // case STANDARD_ACIONS_TYPE.LOGIN_IN:
        //     return authProvider.login(payload);
        // case STANDARD_ACIONS_TYPE.GET_ONE:
        //     return dataProvider.getOne(resource, payload);
        default:
            return {};
    }
};
